import { Component, HostListener, OnInit } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { TeamsService } from 'src/app/services/core/teams.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-change-account',
  templateUrl: './change-account.page.html',
  styleUrls: ['./change-account.page.scss'],
})
export class ChangeAccountPage implements OnInit {

  accounts: any;

  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    query: '',
  };

  state: state = {};

  user: user;

  view: any = {
    accounts: [],
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    title: 'change_account',
  }

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private navParams: NavParams,
    private projects: ProjectsService,
    private teams: TeamsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.accounts = this.navParams.get('accountsService');
    this.user = this.userService.getUser() || {};
  }

  async addAccount() {
    this.accounts.setMultiMode(true);
    this.events.publish('login:show');
  }

  afterLogout() {
    this.loadAccounts(true)
      .then((accounts: user[]) => {
        if (accounts && accounts.length && (accounts[0] && accounts[0].uid && accounts[0].uid !== -1)) {
          this.doRefresh();
        } else {
          this.dismiss(null, 'done');
          this.navCtrl.navigateRoot('/login');
        }
      });
  }

  calcViewData() {
    this.view = this.viewService.calcVars(this.view);

    if (this.view.accounts && this.view.accounts.length) {
      this.view.accounts.forEach((account: user) => {
        account.checked = (account.uid === this.user.uid);
      });
    }
  }

  async dismiss(data: any = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  doRefresh(event: any = null) {
    this.loadAccounts(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (event) {
          event.target.complete();
        }
      });
  }

  ionViewDidEnter() {
    this.loadAccounts()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  loadAccounts(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.accounts.getAll({}, blForceRefresh)
        .then((accounts: user[]) => {
          this.view.accounts = accounts;
          this.calcViewData();

          this.loadAccountsTeams(blForceRefresh)
            .then((accounts: user[]) => {
              resolve(accounts);
            })
            .catch(() => {
              resolve(accounts);
            });
        })
        .catch(reject);
    });
  }

  loadAccountsTeams(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this.view.accounts && this.view.accounts.length) {
        this.view.accounts.forEach(async (account: user, index: number) => {
          try {
            const teamsByUser: any = await this.teams.getByUserUid(account.uid, blForceRefresh);

            if (teamsByUser && teamsByUser.length) {
              account.teams = teamsByUser.sort((a: team, b: team) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              });
            }

            if (index === (this.view.accounts.length - 1)) {
              resolve(this.view.accounts);
            }
          } catch (e) {
            console.warn('> teamsByUser loading failed', e);

            if (index === (this.view.accounts.length - 1)) {
              resolve(this.view.accounts);
            }
          }
        });
      }
    });
  }

  logout(account: user) {
    this.accounts.logout(account)
      .then(() => {
        this.afterLogout();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  logoutAll() {
    this.userService.logout()
      .then(() => {
        this.teams.setCurrentTeam(null);
        this.afterLogout();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {
    this.calcViewData();
    this.user = this.userService.getUser() || {};

    this.events.subscribe("appcms:user:updated", () => {
      this.doRefresh();
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewData();
  }

  runSearch(event: any = null) {
    const query: string = this.search.query.toLowerCase();

    this.view.accounts.forEach((account: any) => {
      account.hidden = false;

      if (!!account.teams) {

        const filteredTeams: any[] = account.teams.filter((team: any) => {
          team.hidden = team.name.toLowerCase().indexOf(query.toLowerCase()) === -1;
          return !team.hidden;
        });

        account.hidden = !filteredTeams.length;
      }
    });
  }

  switchTo(account: user) {
    this.userService.setUser(account);

    if (!!account && !!account.teams && !!account.teams.length) {
      this.teams.setCurrentTeam(account.teams[0]);
      this.projects.setCurrent(null);
    }

    this.dismiss(null, 'done');
  }

  switchToTeam(team: team, user: user | null = null) {
    this.teams.setCurrentTeam(team);
    this.projects.setCurrent(null);

    if (!!user && (user.uid !== this.user.uid)) {
      return this.switchTo(user);
    } else {
      this.dismiss(null, 'done');
    }
  }

  thumbnailLoadingFailed(account: user | team, photo: string | null = null) {
    account.photo = (photo || this.fallbackImg);
  }

}